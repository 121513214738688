<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Vipiska kassa o'zgartirish</h3>
            </div>
          </div>

          <div class="card-body">
            <v-col cols="6" :class="'mx-auto'">
              <div v-if="getVipiskaDate.hasOwnProperty('client_name')">
                <!-- <div class="form-group">
                  <label for="input-live">Summa:</label>

                  <b-form-input v-model="getVipiskaDate.amount"></b-form-input>
                </div> -->

                <div class="form-group">
                  <label for="input-live">Summa:</label>

                  <b-form-input
                    v-currency="options"
                    v-model="getVipiskaDate.amount"
                  ></b-form-input>
                </div>
                <div>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="getVipiskaDate.oper_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label for="input-live">Sana:</label>
                      <input
                        type="text"
                        v-model="getVipiskaDate.oper_date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="form-control"
                        id="kt_datatable_search_query"
                      />
                    </template>
                    <v-date-picker
                      v-model="getVipiskaDate.oper_date"
                      scrollable
                      :show-current="showCurrent"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false"
                        >Yopish</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(getVipiskaDate.oper_date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
              <div v-else>
                <!-- <div class="form-group">
                  <label for="input-live">Summa:</label>
                  <b-form-input v-model="getVipiskaDate.amount"></b-form-input>
                </div> -->
                <div class="form-group">
                  <label for="input-live">Summa:</label>

                  <b-form-input
                    v-currency="options"
                    v-model="getVipiskaDate.amount"
                  ></b-form-input>
                </div>

                <div>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="getVipiskaDate.oper_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label for="input-live">Sana:</label>

                      <input
                        type="text"
                        v-model="getVipiskaDate.oper_date"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="form-control"
                        id="kt_datatable_search_query"
                      />
                    </template>
                    <v-date-picker
                      v-model="getVipiskaDate.oper_date"
                      scrollable
                      :show-current="showCurrent"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false"
                        >Yopish</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(getVipiskaDate.oper_date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
              <div class="mt-5">
                <button @click="submit" class="btn btn-success w-100">
                  SAQLASH
                </button>
              </div>
            </v-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters, mapActions } from 'vuex'
import { CurrencyDirective } from 'vue-currency-input'
export default {
  data() {
    return {
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      price: '',
      age: '',
      showCurrent: false,
      date2: '',
      menu: false,
      modal: false,
      submitdata: {},
      menu2: false
    }
  },
  directives: { currency: CurrencyDirective },
  computed: {
    ...mapGetters(['getVipiskaDate']),
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  created() {
    // this.$store.dispatch("getTransferDetails", this.$route.params.id);
    this.getVipiskaDate.oper_date
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Vipiska' },
      { title: 'Vipisk  a malumotlari' }
    ])
    this.getTransferDetails(this.$route.params.id)
  },
  methods: {
    submit() {
      const id = {
        id: this.$route.params.id
      }
      const data = {
        amount: this.getVipiskaDate.amount.split(',').join(''),
        oper_date: this.getVipiskaDate.oper_date
      }
      this.increment({
        data,
        id
      })
    },
    ...mapActions({
      increment: 'transferEdit'
    }),
    ...mapActions(['getTransferDetails'])
  }
}
</script>

<style scoped>
.form-group label,
.form-control {
  font-size: 1.1rem !important;
}
.theme--light /deep/ .v-label--is-disabled {
  color: #000 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>
